.input{
    display: flex;
    width: 95%;
    position: relative;
    align-items: center;
}

.input_box{
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 25px;
    border: none;
    transition: 0.2s;
    box-shadow: inset 0 0 5px black;

}
.input_box:focus{
    box-shadow: 0 0 10px 1000px rgba(0,0,0,0.5) ;
    outline: none;
}
.input_submit{
    position:absolute;
    width: 50px;
    height: 50px;
    margin: 12px;
    border-radius: 50px;
    right: 0px;
    border: none;
    font-size: 15px;
    background-color: #2f74c0;
    color: white;
    transition: 0.2s all;
    box-shadow: 0 0 10px black;

}

.input_submit:active{
    transform: scale(0.8);
    box-shadow: 0 0 5px black;
}
.todo{
    display: flex;
    width: 47.5%;
    flex-direction: column;
    padding: 15px;
    border-radius: 5px;
    background-color: rgb(50,195,205);
   
}
.todos__heading{
    font-size: 30px;
    color: white;
}
.single_todo{
    display: flex;
    border-radius: 5px;
    padding: 20px;
    margin-top: 15px;
    background-image: url('https://img.freepik.com/free-photo/crumpled-yellow-paper-background-close-up_60487-2390.jpg?ext=jpg&size=626');
    transition: 0.2s;
}
.single_todo:hover{
    box-shadow: 0 0 5px black;
    transform: scale(1.03);
}
.single_todo--text{
    flex: 1;
    padding: 5px;
    border: none;
    font-size: 20px;
}
.single_todo--text:focus{
    outline: none;
}
.icon {
    margin-left: 10px;
    font-size: 25px;
    cursor: pointer;
}
.container {
    display: flex;
    width: 95%;
    margin-top: 10px;
    justify-content: space-between;
    align-items: flex-start;
}
.todos{

}
.logout{
    position:absolute;
    width: 40px;
    height: 40px;
    margin: 12px;
    border-radius: 50px;
    right: 0px;
    border: none;
    font-size: 15px;
    background-color: rgb(235,103,80);
    color: white;
    transition: 0.2s all;
    box-shadow: 0 0 10px black;
}
.logout:active{
    transform: scale(0.8);
    box-shadow: 0 0 5px black;
}

.login_h{
    padding-top: 10%;
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2f74c0;
    font-family: "Neucha", cursive;
}
.input_login{
    margin-bottom: 2rem;
    width:40%;
    border-radius: 50px;
    padding: 20px 30px;
    font-size: 25px;
    border: none;
    transition: 0.2s;
    box-shadow: inset 0 0 5px black;
}
.input_login:active{
    transform: scale(0.8);
    box-shadow: 0 0 5px black;
}
.login_submit{
   
    padding: 10px;
    margin: 12px;
    border-radius: 20px;
    right: 0px;
    border: none;
    font-size: 20px;
    background-color: white;
    color: #2f74c0;
}


@media (max-width:700px) {
    .input{
        width: 95%;
    }
    .todo{
        width: 95%;
        margin-bottom: 10px;
    }
   .container{
    width: 95%;
    flex-direction: column;
   }
    
}

.remove{
    background-color: rgb(235,103,80);
}
.drag{
    box-shadow:0 0 25px black ;
}

.dragactive{
    background-color: rgb(0,221,236);
}
.dragcomplete{
    background-color: rgb(255,38,0);
}